import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Layout from '../components/layout';
import * as classes from './account.module.scss';

export default function Account() {
  return (
    <Layout>
      <div className={classes.root} style={{ paddingTop: 40 }}>
        <Container maxWidth='md'>
          <Paper>
            <div className={classes.account}>
              <div className={classes.accountHeader}>
                <h1>Account</h1>
              </div>
              <Divider orientation='horizontal' />

              <div className={classes.accountContent}>
                <div>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value='end'
                        control={
                          <Checkbox
                            color='primary'
                            checked={false}
                            onChange={() => {}}
                          />
                        }
                        label={
                          <span>I want to receive news, feature updates and offers from TabManager.io.</span>
                        }
                        labelPlacement='end'
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </Paper>
        </Container>
      </div>
    </Layout>
  );
}
